export const NEW_PAGE_WITHOUT_ID = 'new'

export const LINKS = {
  home: '/',
  login: '/login',
  logout: '/logout',
  bpm: '/bpm',
  bpmResult: (id: string) => `/bpm/${id}`,
  booking: '/booking',
  appointment: (branchId: string, opportunityId?: string) =>
    `/${branchId}/buying/${opportunityId || NEW_PAGE_WITHOUT_ID}/appointment`,
  customer: (branchId: string, opportunityId: string) =>
    `/${branchId}/buying/${opportunityId}/customer`,
  vehicle: (branchId: string, opportunityId: string) =>
    `/${branchId}/buying/${opportunityId}/vehicle`,
  notes: (branchId: string, opportunityId: string) =>
    `/${branchId}/buying/${opportunityId}/notes`,

  handover_customer: (branchId: string) => `/${branchId}/handover/customer`,
  handover_slot: (branchId: string, orderId: string) =>
    `/${branchId}/handover/${orderId}/slot`,
  handover_order: (branchId: string) => `/${branchId}/handover/order`,
  handover_notes: (branchId: string, orderId: string) =>
    `/${branchId}/handover/${orderId}/notes`,
  handover_appointment: (branchId: string, orderId: string) =>
    `/${branchId}/handover/${orderId}/appointment`,
  disable_homedelivery: (branchId: string, bookingUnavailabilityId?: string) =>
    `/${branchId}/disable-homedelivery/${
      bookingUnavailabilityId ?? NEW_PAGE_WITHOUT_ID
    }`,

  reclamation_customer: (branchId: string) =>
    `/${branchId}/reclamation/customer`,
  reclamation_slot: (branchId: string, reclamationId: string) =>
    `/${branchId}/reclamation/${reclamationId}/slot`,
  reclamation_order: (branchId: string) => `/${branchId}/reclamation/order`,
  reclamation_notes: (branchId: string, reclamationId: string) =>
    `/${branchId}/reclamation/${reclamationId}/notes`,
  reclamation_appointment: (branchId: string, orderId: string) =>
    `/${branchId}/reclamation/${orderId}/appointment`,

  disabled_buyout: (branchId: string, bookingUnavailabilityId?: string) =>
    `/${branchId}/disable-buyout/${
      bookingUnavailabilityId ?? NEW_PAGE_WITHOUT_ID
    }`
}
